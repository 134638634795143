<template>
  <section class="xbts-smartholder">
    <div class="card w-100">
      <div class="card-body">
        <p class="x-crumbs">
          <router-link to="/">XBTS</router-link>
          <i class="mdi mdi-chevron-right"></i> <span>Asic Miner</span></p>
        <hr/>
        <h1 class="card-title text-white">Самый доступный майнинг в мире</h1>
        <p>Актив ASIC привязан 1 к 1 к реальному хэшрейту оборудования майнинговых площадок партнёров XBTS.</p>
        <p>Новые активы доступны на бирже XBTS только при наличии реального работающего оборудования.</p>
        <p>
          Актив ASIC избавляет вас от хлопот связанных с обслуживанием асиков.
          Актив позволяет вам торговать/владеть мощностями майнингового оборудования, в датацентрах партнеров XBTS, за которым следят реальные инженеры.</p>
        <p>Используй реальный хэшрейт для майнинга монет DOGE & Litecoin</p>
        <p>Вы можете разместить свой актив ASIC и получать прибыль от майнинга DOGE, LTC 1 раз в месяц. 1 ASIC = 1 Mhs SCRYPT</p>
        <p>Ставка в программе майнинга на 3, 6 или 12 месяцев дает вам право на получение реальных монет от майнинга.</p>
        <p>3 месяца - 60% Hashrate, 6 месяцев - 75% Hashrate, 12 месяцев - 100% Hashrate</p>
        <p>Minimum 2000 ASIC</p>

      </div>
    </div>

    <div class="card w-100 mt-3">
      <div class="card-body">
        <table v-if="holders" class="table table-borderless">
          <tbody>
          <tr>
            <th>Miners</th>
            <td>1</td>
          </tr>
          <tr>
            <th>Hashrate</th>
            <td>500 Ghs (Scrypt)</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="card w-100 mt-3">
      <div class="card-body">
        <b-tabs class="tab-minimal tab-minimal-success">
          <b-tab active>
            <template slot="title">
              <i class="icon-list"></i> {{ $t('smartholder.assets') }}
            </template>
            <table class="table table-striped table-borderless w-100 col-sm-auto">
              <thead>
              <tr>
                <th class="border-bottom-0" scope="col">{{ $t('assets.asset') }}</th>
                <th class="border-bottom-0 text-capitalize" scope="col">Добыто</th>
                <th class="border-bottom-0" scope="col">Hahrate</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-left">
                  <router-link class="text-white" :to="'/asset/XBTSX.DOGE'">
                    <img
                        :src="'images/assets/doge.png'"
                        class="asset-symbol mr-2"/>Dogecoin (DOGE)
                  </router-link>
                </td>
                <td class="text-left">
                  423919.18467975
                </td>
                <td class="text-left">500 Ghs
                </td>
              </tr>
              <tr>
                <td class="text-left">
                  <router-link class="text-white" :to="'/asset/XBTSX.LTC'">
                    <img
                        :src="'images/assets/ltc.png'"
                        class="asset-symbol mr-2"/>Litecoin (LTC)
                  </router-link>
                </td>
                <td class="text-left">
                  231.28229212
                </td>
                <td class="text-left">500 Ghs
                </td>
              </tr>
              </tbody>
            </table>
            <p class="small mt-1">
              * text
            </p>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="icon-people"></i> Miners
            </template>
            <table class="table table-borderless table-striped table-responsive-sm">
              <thead class="">
              <tr>
                <th class="border-bottom-0" scope="col">#</th>
                <th class="border-bottom-0" scope="col">Holder</th>
                <th class="border-bottom-0" scope="col">Stake STH</th>
                <th :title="$t('smartholder.bonus_info')" v-b-tooltip.html.top class="border-bottom-0" scope="col">+Bonus</th>
                <th class="border-bottom-0" scope="col">Bonus %</th>
                <th class="border-bottom-0" scope="col">Personal %</th>
                <th class="border-bottom-0" scope="col"><span :title="'Annual percentage yield'" v-b-tooltip.html.top>APY</span>
                </th>
                <th class="border-bottom-0" scope="col">{{ $t('smartholder.months') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr :key="idxHolder" v-for="(item, idxHolder) in holders.holders">
                <td>{{ idxHolder + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ formatNum(item.real_amount, '# ##0.00') }}</td>
                <td>{{ formatNum(item.bonus_amount) }}</td>
                <td>{{ item.bonus_percent }}%</td>
                <td>{{ item.person_percent }}%</td>
                <td>{{ (item.person_percent * item.months * 2).toFixed(2) }}%</td>
                <td>{{ item.months }}</td>
              </tr>
              </tbody>
            </table>
            <p class="small mt-1">
              * {{$t('smartholder.bonus_info')}}
            </p>


          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="icon-book-open"></i> Начисления
            </template>
            <div class="row">
              <div :key="idxReports" class="mt-2 mb-3 col-md-6" v-for="(item,idxReports) in reports">
                <div class="container">
                  <div class="col">
                    <div class="overflow-hidden mb-1 border border-dark">
                      <div class="card-header">
                        <h4 class="m-0 text-warning text-center">#{{ item.id }}
                          {{ item.date.substr(0, 10) }}</h4>
                      </div>
                      <div class="">
                        <table class="table mb-0 table-borderless table-striped">
                          <tbody>
                          <tr :key="idxReport" v-for="(report, idxReport) in item.data">
                            <td class="text-right">{{ report.ticker }} <img
                                :src="'images/assets/'+report.ticker.toLowerCase()+'.png'"
                                class="asset-symbol ml-3"/></td>
                            <td class="text-left">{{ report.total }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="icon-briefcase"></i> Мой Майнинг
            </template>
            <p v-show="!this.$root.pin">
              <router-link class="btn btn-info text-uppercase" to="/auth-pages/login"><i
                  class="icon-login ml-2 mr-2 icon-16"></i> {{ $t('app.login') }}
              </router-link>
            </p>

            <MyStakes v-if="this.$root.pin"/>
          </b-tab>

        <b-tab v-if="this.$root.pin && profit">
          <template slot="title">
            <i class="icon-briefcase"></i> {{ $t('smartholder.received_payments') }}
          </template>

          <h4 class="text-white text-center">{{ $t('smartholder.title_total_assets') }}</h4>


              <table class="table mb-0 table-borderless table-striped">
                <tbody>
                <tr :key="idxProfit" v-for="(item, idxProfit) in profit">
                  <td class="text-right">{{ idxProfit }} <img
                      :src="'images/assets/'+idxProfit.toLowerCase()+'.png'"
                      class="asset-symbol ml-3"/></td>
                  <td class="text-left">{{ item }}</td>
                </tr>
                </tbody>
              </table>



        </b-tab>

        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SmartHolder",
  components: {
    MyStakes: () => import('@/components/smartholder/MyStakes')
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "XBTS",
          href: "/#/"
        },
        {
          text: "Asic Miners",
          active: true
        },
      ],
      orderedObj: {}
    }
  },
  computed: {
    assetsNoq() {
      return this.$store.getters['smartholder/assetsNoq']
    },
    assetsPay() {
      return this.$store.getters['smartholder/assetsPay']
    },
    holders() {
      return this.$store.getters['smartholder/holders']
    },
    reports() {
      return this.$store.getters['smartholder/reports']
    },
    profit() {
      return this.$store.getters['smartholder/profit']
    },
    orderedAssetsNoQ: function () {
      if (this.assetsNoq) {
        return _.orderBy(this.assetsNoq, 'q', 'desc')
      } else {
        return []
      }

    },
    orderedAssetsPay: function () {
      if (this.assetsPay) {
        return _.orderBy(this.assetsPay, 'q', 'desc')
      } else {
        return []
      }

    }
  },
  methods: {

  },
  async created() {
    const latestReportCountRemote = await this.$store.dispatch('smartholder/latestReportCountRemote');
    const latestReport = this.$store.getters['smartholder/latestReport'];
    if (latestReport < latestReportCountRemote) {
      await this.$store.dispatch('smartholder/fetchLatestReports');
    }

    if (this.$root.pin) {
      await this.$store.dispatch('smartholder/getMyProfit', this.$root.account.account.name);
    }
  }
}
</script>

<style scoped>
.asset-symbol {
  width: 18px;
  height: 18px;
}

.vue-slide-bar-component {
  min-height: auto !important;
}
</style>
